
import { defineComponent } from "vue";
// import KTNotificationsMenu from "@/layout-admin/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout-admin/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout-admin/header/partials/UserMenu.vue";

export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const path = window.location.origin + "/";
    return {
      path,
    };
  },
});

<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--begin::Admin panel-->
    <router-link
      :to="{ name: 'base' }"
      class="d-flex align-items-center ms-1 ms-lg-3"
      title="Masuk ke Dashboard"
    >
      <!--begin::drawer toggle-->
      <div class="btn btn-icon w-30px h-30px w-md-40px h-md-40px">
        <i class="bi bi-display fs-2 text-white"></i>
      </div>
      <!--end::drawer toggle-->
    </router-link>
    <!--end::Admin panel-->

    <!--begin::Activities-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::drawer toggle-->
      <div
        class="btn btn-icon w-30px h-30px w-md-40px h-md-40px"
        id="kt_activities_toggle"
      >
        <i class="bi bi-bell fs-2 text-white"></i>
      </div>
      <!--end::drawer toggle-->
    </div>
    <!--end::Activities-->

    <!--begin::Quick links-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::Menu-->
      <div
        class="btn btn-icon w-30px h-30px w-md-40px h-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <i class="bi bi-question-circle fs-2 text-white"></i>
      </div>
      <!-- <KTQuickLinksMenu></KTQuickLinksMenu> -->
      <!--end::Menu-->
    </div>
    <!--end::Quick links-->

    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img :src="path + 'media/avatars/150-26.jpg'" alt="metronic" />
      </div>
      <KTUserMenu></KTUserMenu>

      <span class="ms-3 d-none d-md-block text-white">Max Smith</span>
      <!--end::Menu-->
    </div>
    <!--end::User -->

    <!--begin::Heaeder menu toggle-->
    <div
      class="d-flex align-items-center d-lg-none d-none ms-2 me-n3"
      title="Show header menu"
    >
      <div
        class="btn btn-icon btn-active-light-primary"
        id="kt_header_menu_mobile_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotune/text/txt001.svg" />
        </span>
      </div>
    </div>
    <!--end::Heaeder menu toggle-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import KTNotificationsMenu from "@/layout-admin/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout-admin/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout-admin/header/partials/UserMenu.vue";

export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const path = window.location.origin + "/";
    return {
      path,
    };
  },
});
</script>

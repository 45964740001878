<template>
  <!--begin::Header-->
  <div id="kt_header" style="" class="header align-items-stretch bg-primary">
    <!--begin::Container-->
    <div
      :class="{
        'container-fluid': headerWidthFluid,
        'container-xxl': !headerWidthFluid,
      }"
      class="d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Aside mobile toggle-->
      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1"
        title="Show aside menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_aside_mobile_toggle"
        >
          <span class="svg-icon svg-icon-2x mt-1">
            <inline-svg
              :src="path + 'media/icons/duotune/abstract/abs015.svg'"
            />
          </span>
        </div>
      </div>
      <!--end::Aside mobile toggle-->

      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="#" class="d-lg-none d-none">
          <img
            alt="Logo"
            :src="path + 'media/logos/logo-2.svg'"
            class="h-30px"
          />
        </a>
      </div>
      <!--end::Mobile logo-->

      <!--begin::Wrapper-->
      <div
        class="
          d-flex
          align-items-stretch
          justify-content-between
          flex-lg-grow-1
        "
      >
        <!--begin::Navbar-->
        <div
          class="d-flex align-items-stretch flex-grow-1 w-100"
          id="kt_header_menu_nav"
        >
          <!-- <KTMenu></KTMenu> -->
          <!-- begin:Search -->
          <div class="d-flex align-items-center flex-grow-1 ms-1 ms-lg-3">
            <div
              class="
                d-flex
                align-items-center
                flex-grow-1
                bg-white
                rounded-pill
                border
                pr-15
              "
            >
              <span class="svg-icon svg-icon-1 mr-2 ms-3">
                <inline-svg
                  :src="path + 'media/icons/duotune/general/gen004.svg'"
                />
              </span>
              <input
                type="text"
                class="form-control border-0 rounded-pill"
                placeholder="Search"
              />
            </div>
          </div>
          <!-- end:Search -->
        </div>
        <!--end::Navbar-->
        <div class="w-50 d-lg-block d-none"></div>

        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <KTTopbar></KTTopbar>
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import KTTopbar from "@/layout-admin/header/Topbar.vue";
// import KTMenu from "@/layout-admin/header/Menu.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    // KTMenu,
  },
  setup() {
    const path = window.location.origin + "/";
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      path,
    };
  },
});
</script>
